<template>
  <div class="subsales-otp-form row align-items-end">
    <fd-form-section title="General">
      <fd-input
        v-if="showPropertyType"
        v-model="offerToRent.propertyType"
        class="col-12 sm-col-6 xl-col-4 px-1 mb-2"
        label="Property Type"
        name="propertyType"
        type="text"
        disabled
        :validators="[...getIsFieldRequired()]"
      ></fd-input>
      <div class="col-12 px-1 mb-2">
        <fd-input
          v-model="offerToRent.propertyAddress"
          class=""
          label="Property/Premises for rent"
          name="propertyAddress"
          type="text"
          :validators="[...getIsFieldRequired()]"
        ></fd-input>
        <fd-button
          v-if="autofillAddress"
          class="bordered main mt-1"
          @click="offerToRent.propertyAddress = autofillAddress() || ''"
        >
          Autofill From Property
        </fd-button>
      </div>
      <fd-input
        v-model="offerToRent.landlordAddress"
        class="col-12 px-1 mb-2"
        label="Landlord's Postal Address"
        name="landlordAddress"
        type="text"
        :validators="[...getIsFieldRequired()]"
      ></fd-input>
    </fd-form-section>

    <fd-form-section v-if="showTenant" title="Tenant">
      <fd-input
        v-model="offerToRent.tenantName"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Tenant Name"
        name="tenantName"
        type="text"
        :validators="[...getIsFieldRequired()]"
      ></fd-input>
      <fd-input
        v-model="offerToRent.tenantAddress"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Postal Address"
        name="tenantAddress"
        type="text"
        :validators="[...getIsFieldRequired()]"
      ></fd-input>
    </fd-form-section>

    <fd-form-section title="Fees Related">
      <fd-input
        v-model="offerToRent.rentPrice"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Monthly Rent"
        name="rentPrice"
        type="number"
        :validators="[...getIsFieldRequired()]"
      ></fd-input>
      <fd-input
        v-model="offerToRent.billInclusive"
        class="col-12 px-1 mb-2"
        label="Bill Inclusive"
        name="billInclusive"
        type="text"
        :validators="[...getIsFieldRequired()]"
      ></fd-input>
      <div class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1">
        <fd-input
          v-model="offerToRent.rentInAdvance"
          class="col-12 mb-2"
          label="Rent in Advance"
          name="rentInAdvance"
          type="number"
          :validators="[...getIsFieldRequired(), price]"
        ></fd-input>
        <fd-input
          v-model="offerToRent.securityDeposit"
          class="col-12 mb-2"
          label="Security Deposit"
          name="securityDeposit"
          type="number"
          :validators="[price]"
        ></fd-input>
        <fd-input
          v-model="offerToRent.utilitiesDeposit"
          class="col-12 mb-2"
          label="Utility Deposit"
          name="utilitiesDeposit"
          type="number"
          :validators="[price]"
        ></fd-input>
        <fd-input
          v-model="offerToRent.otherDeposit"
          class="col-12 mb-2"
          label="Other Deposit"
          name="otherDeposit"
          type="number"
          :validators="[price]"
        ></fd-input>
        <fd-input
          v-model="offerToRent.remark"
          class="col-12 mb-2"
          label="Other Deposit's Remark"
          name="remark"
          type="text"
          :validators="otherDepositValidation"
        ></fd-input>
        <fd-input
          v-model="offerToRent.subtotal"
          class="col-12 mb-2"
          label="Subtotal"
          name="subtotal"
          type="number"
          :validators="[...getIsFieldRequired(), price]"
          readonly
        ></fd-input>
      </div>
      <div class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2">
        <fd-input
          v-model="offerToRent.earnestDeposit"
          class="col-12 mb-2"
          label="Earnest Deposit"
          name="earnestDeposit"
          type="number"
          :validators="[...getIsFieldRequired(), price]"
        ></fd-input>
        <fd-input
          v-model="offerToRent.balanceToPay"
          class="col-12 mb-2"
          label="Balance To Pay"
          name="balanceToPay"
          type="number"
          :validators="[...getIsFieldRequired(), price]"
          readonly
        ></fd-input>
      </div>
    </fd-form-section>

    <fd-form-section title="Contract Terms">
      <fd-date-picker
        v-model="offerToRent.commencementDate"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Commencement Date"
        name="commencementDate"
        :validators="[...getIsFieldRequired()]"
      ></fd-date-picker>
      <fd-date-picker
        v-model="offerToRent.rentFreePeriodFrom"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Rent Free Period granted (From)"
        name="rentFreePeriodFrom"
        :validators="rentFreeValidation"
      ></fd-date-picker>
      <fd-date-picker
        v-model="offerToRent.rentFreePeriodTo"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Rent Free Period granted (To)"
        name="rentFreePeriodTo"
        :validators="rentFreeValidation"
      ></fd-date-picker>
      <fd-select
        v-model="offerToRent.tenancyTerms"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Fixed Term of Tenancy (years)"
        name="tenancyTerms"
        :options="['1', '2', '3']"
        :optionLabel="(val) => `${val} Years`"
        :optionValue="(val) => val"
        :validators="[...getIsFieldRequired()]"
      ></fd-select>
      <div class="col-12 sm-col-6 xl-col-4 px-1 mb-2 row">
        <label class="label col-12">Further Term</label>
        <div class="col-12 d-flex align-items-center">
          <fd-select
            v-model="offerToRent.renewTerm"
            class="col-6"
            name="furtherTerm"
            :options="['1', '2', '3']"
            :optionLabel="(val) => `${val} Years`"
            :optionValue="(val) => val"
            :validators="[...getIsFieldRequired()]"
          ></fd-select>
          <span class="px-2">at</span>
          <fd-input
            v-model="offerToRent.renewFurtherTerm"
            class="col-6"
            name="rentalFurtherTerm"
            placeholder="Market Rate / %"
            :validators="[...getIsFieldRequired()]"
          ></fd-input>
        </div>
      </div>
    </fd-form-section>

    <fd-form-section title="Tenancy Terms">
      <fd-input
        v-model="offerToRent.totalDisbursement"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Total Disbursement"
        name="totalDisbursement"
        type="number"
        :validators="[...getIsFieldRequired(), price]"
      ></fd-input>
      <fd-input
        v-model="offerToRent.borneBy"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="(T.A.) are to be borne by"
        name="borneBy"
        type="text"
        :validators="[...getIsFieldRequired()]"
      ></fd-input>
      <fd-input
        v-model="offerToRent.parkingLotUnit"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Parking Lot"
        name="parkingLotUnit"
        type="number"
        :validators="[]"
      ></fd-input>
      <fd-select
        v-model="offerToRent.propertyCondition"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Property Condition"
        name="propertyCondition"
        :options="conditionOptions"
        :validators="[...getIsFieldRequired()]"
      ></fd-select>
      <fd-date-picker
        v-model="offerToRent.viewingDate"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Viewing Date"
        name="viewingDate"
        :validators="[...getIsFieldRequired()]"
      ></fd-date-picker>
      <fd-textarea
        v-model="offerToRent.otherCondition"
        class="col-12 px-1 mb-2"
        label="Other Terms & Condition"
        name="otherCondition"
        :validators="[]"
      ></fd-textarea>
    </fd-form-section>

    <fd-form-section title="Execution Terms">
      <fd-input
        v-model="offerToRent.workingDayTA"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="TA Within Working Days (Numbers)"
        name="workingDayTA"
        type="number"
        :validators="[...getIsFieldRequired(), positiveInteger]"
      ></fd-input>
      <fd-input
        v-model="offerToRent.payeeName"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="Landlord/Appointed Solicitor"
        name="payeeName"
        :validators="[]"
      ></fd-input>
      <fd-input
        v-model="offerToRent.workingDayOTR"
        class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
        label="OTR Within Working Days (Numbers)"
        name="workingDayOTR"
        type="number"
        :validators="[...getIsFieldRequired(), positiveInteger]"
      ></fd-input>
    </fd-form-section>
  </div>
</template>

<script>
import Big from "big.js";
import {
  required,
  minValue,
  price,
  positiveInteger
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { furnishingType } from "@/enums";
import { convertNullIfEmpty as convNull } from "@/utils/null-check";

export default {
  components: {},
  mixins: [],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    otrData: {
      type: Object
    },
    autofillAddress: {
      type: Function
    },
    showPropertyType: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    showTenant: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      offerToRent: {
        propertyAddress: "",
        landlordAddress: "",
        tenantName: "",
        tenantAddress: "",
        rentPrice: "",
        billInclusive: "",
        rentInAdvance: "",
        securityDeposit: "",
        utilitiesDeposit: "",
        otherDeposit: "",
        subtotal: "",
        earnestDeposit: "",
        balanceToPay: "",
        remark: "",
        commencementDate: "",
        rentFreePeriodFrom: "",
        rentFreePeriodTo: "",
        tenancyTerms: "",
        renewTerm: "",
        renewFurtherTerm: "",
        totalDisbursement: "",
        borneBy: "",
        parkingLotUnit: "",
        propertyCondition: "",
        viewingDate: "",
        otherCondition: "",
        workingDayTA: "",
        payeeName: "",
        workingDayOTR: ""
      },
      otrToolTip: {},

      minValue,
      price,
      positiveInteger,

      conditionOptions: this.$mapJsonToArray(furnishingType, (e) => ({
        id: e,
        name: e
      }))
    };
  },
  computed: {
    rentFreeValidation() {
      return this.offerToRent.rentFreePeriodFrom ||
        this.offerToRent.rentFreePeriodTo
        ? [required]
        : [];
    },
    otherDepositValidation() {
      return this.offerToRent.otherDeposit ? [required] : [];
    }
  },
  watch: {
    offerToRent: {
      handler(newVal) {
        this.$emit("change", newVal);
      },
      deep: true
    },
    "offerToRent.rentInAdvance": {
      handler() {
        this.calculateSubtotal();
      }
    },
    "offerToRent.securityDeposit": {
      handler() {
        this.calculateSubtotal();
      }
    },
    "offerToRent.utilitiesDeposit": {
      handler() {
        this.calculateSubtotal();
      }
    },
    "offerToRent.otherDeposit": {
      handler() {
        this.calculateSubtotal();
      }
    },
    "offerToRent.subtotal": {
      handler() {
        this.calculateBalanceToPay();
      }
    },
    "offerToRent.earnestDeposit": {
      handler() {
        this.calculateBalanceToPay();
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    if (this.isEdit) {
      this.offerToRent = this._.cloneDeep(this.otrData);
    }
  },
  methods: {
    getIsFieldRequired() {
      return this.required ? [required] : [];
    },
    calculateSubtotal() {
      let rentInAdvance = new Big(
        convNull(this.offerToRent.rentInAdvance) || 0
      );
      let securityDeposit = new Big(
        convNull(this.offerToRent.securityDeposit) || 0
      );
      let utilitiesDeposit = new Big(
        convNull(this.offerToRent.utilitiesDeposit) || 0
      );
      let otherDeposit = new Big(convNull(this.offerToRent.otherDeposit) || 0);

      let subtotal = rentInAdvance
        .plus(securityDeposit)
        .plus(utilitiesDeposit)
        .plus(otherDeposit);

      this.offerToRent.subtotal = subtotal.toString();
    },
    calculateBalanceToPay() {
      let subtotal = new Big(convNull(this.offerToRent.subtotal) || 0);
      let earnestDeposit = new Big(
        convNull(this.offerToRent.earnestDeposit) || 0
      );
      this.offerToRent.balanceToPay = subtotal.sub(earnestDeposit).toString();
    }
  }
};
</script>

<style lang="scss"></style>
